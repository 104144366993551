import React, { Component } from "react";
import Results from "../../components/Results/Results";
import { withRouter } from "react-router-dom";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Box, Spinner } from "@chakra-ui/core";
import decrypt from "../../services/decrypt";

const decryptCredentials = async () => {
  const decryptedAccessKeyId = await decrypt(
    process.env.REACT_APP_ENCRYPTED_AWS_ACCESS_KEY_ID
  );

  const decryptedSecretAccessKey = await decrypt(
    process.env.REACT_APP_ENCRYPTED_AWS_SECRET_ACCESS_KEY
  );

  return {
    accessKeyId: decryptedAccessKeyId,
    secretAccessKey: decryptedSecretAccessKey,
  };
};
class ResultsContainer extends Component {
  state = {
    loading: true,
    results: [],
  };

  async componentDidMount() {
    // Get decrypted credentials
    const credentials = await decryptCredentials();

    const s3 = new AWS.S3({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
    });
    let folder = `skoda-pwa`;
    let fileName = `${uuidv4().replace(/\-/g, "")}.jpg`;
    var Location = null;
    let buf = new Buffer.from(
      this.props.location.state.img.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    let params = {
      Bucket: `xane-user-data`,

      Key: `${folder}/${fileName}`, // File name you want to save as in S3
      Body: buf,
      ACL: "public-read",
      ContentType: `image/jpeg`,
      ContentEncoding: "base64",
    };
    s3.upload(params, async (err, data) => {
      if (err) {
        throw err;
      }

      Location = data.Location;
      // let url = 'https://xonq5mnyms35p76pmbvbkobrei0mdtxy.lambda-url.ap-south-1.on.aws';
      let url = "https://api.xanegin.assist.marutisuzuki.com/stage/query";
      let body = {
        // model_url: 'https://s3.ap-south-1.amazonaws.com/xane-data/projects/skoda/model/onnx/image/vision-quantized.onnx',
        // labels_url: 'https://s3.ap-south-1.amazonaws.com/xane-data/projects/skoda/model/onnx/image/vision.json',
        project: "msil-assist",
        image_url: Location,
        limit: 3,
      };
      let options = {
        headers: {
          "Content-Type": "application/json",
          "x-key":
            "a688d8cdbc0a4ebebf5f24c545ceb3693942720f2fb84d84b35d1834b7b7eb66",
        },
      };

      axios.post(url, body, options).then((response) => {
        let filteredResponses = response.data.data.predictions.splice(0, 3);
        this.setState({
          results: filteredResponses,
          loading: false,
        });
      });
    });
  }

  render() {
    const { results } = this.state;
    return this.state.loading ? (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="#103a2f"
        h="100vh"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#103a2f"
          size="xl"
        />
      </Box>
    ) : (
      <div>
        <Results
          image={this.props.location.state.img}
          results={results ? results : []}
        />
      </div>
    );
  }
}

export default withRouter(ResultsContainer);
